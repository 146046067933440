import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {ScreenContext, ErrorContext, StartContext} from './context';
import { Screens } from './enum';
import { DirectUpload } from '@rails/activestorage'
import {useDispatch, useSelector} from 'react-redux';
import {updateProgress as updateUploadProgressValue} from './store/upload-progress';
import classnames from "classnames";
import {support} from './utils';

/**
 * Formats file size.
 *
 * @param {String} bytes
 * @param {Number} decimals
 * @returns
 */
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`
}

/**
 * Dropzone inner component.
 *
 * @param {Object} props
 * @returns
 */
const UsdValidatorDropzoneInner = (props) => {
  const {files, openHandler, isDragActive} = props;

  let preview = <></>;

  if(files.length) {
    preview = files.map(file => (
      <div key={file.name} className='dropzone-preview'>
        <div className='dropzone-preview__name'>
          <span>File:</span> {file.name}
        </div>
        <div className='dropzone-preview__size'>
          {formatBytes(file.size)}
        </div>
      </div>
    ));
  }

  const cssClasses = ['dropzone-upload-inner'];
  if (isDragActive) {
    cssClasses.push('dropzone-upload-inner--drag-active');
  }

  return (
    <div className={cssClasses.join(' ')}>
      <div className='dropzone-upload-icon'></div>
      <p className='mb-0'>Drag and drop or <a href={void(0)} onClick={openHandler}>choose .USDZ file to upload</a> <br/>Max 1GB file size</p>
      <div className='dropzone-upload-preview'>
        {preview}
      </div>
    </div>
  );
};

/**
 * DropZone component.
 *
 * @param {Object} props
 * @returns
 */
function UsdValidatorDropzone({screenHandler, errorHandler, fileHandler: setFileDropped}) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  const onDropCallback = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) {
      errorHandler('File not supported.');
      screenHandler(Screens.error);
    }

    dispatch(updateUploadProgressValue(0));

    let list = new DataTransfer();
    list.items.add(acceptedFiles[0]);

    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })));
    setFileDropped(true);

    document.getElementById('user_usdz_package').files = list.files;
  }, []);

  const onErrorCallback = useCallback((error) => {
    setFileDropped(false);
    screenHandler(Screens.error);
  }, []);

  const dropzoneConfig = {
    onError: onErrorCallback,
    onDrop: onDropCallback,
    maxSize: 1024 * 1024 * 1024,
    maxFiles: 1,
    accept: {
      'application/usdz': ['.usdz']
    },
    noClick: true,
    noKeyboard: true,
  };
  const {getRootProps, getInputProps, isDragActive, open} = useDropzone(dropzoneConfig);

  return (
    <div {...getRootProps()}>
      <input {...getInputProps({ multiple: false, id: 'file' })} />
      <UsdValidatorDropzoneInner
        isDragActive={isDragActive}
        files={files}
        openHandler={() => open()}
        filesSetter={(value) => {setFiles(value)}} />
    </div>
  )
}

export default function Form() {
  const [agree, setAgree] = useState(false);
  const [fileDropped, setFileDropped] = useState(false);
  const [currentScreen, setCurrentScreen] = useContext(ScreenContext);
  const [error, setError] = useContext(ErrorContext);
  const [startContext, setStartContext] = useContext(StartContext);
  const dispatch = useDispatch();
  const isTimerCompleted = useSelector(state => state.timer.isCompleted);

  const readyForNextStep = () => agree && fileDropped;

  const nextStep = () => {
    setAgree(false);
    setFileDropped(false);

    let input = document.getElementById('user_usdz_package');
    const url = input.dataset.directUploadUrl
    let file = input.files[0];

    if (!file) {
      setCurrentScreen(Screens.error);
      return;
    }

    const delegate = {
      directUploadWillStoreFileWithXHR: function (request) {
        request.upload.addEventListener('progress', event => this.directUploadDidProgress(event));
      },
      directUploadDidProgress: function (event) {
        const {loaded, total} = event;
        const percentage = loaded / total * 100;
        dispatch(updateUploadProgressValue(percentage));
      }
    };

    if (isTimerCompleted) {
      setStartContext(startContext + 1);
    }

    const upload = new DirectUpload(file, url, delegate);

    setCurrentScreen(Screens.upload);

    upload.create((error, blob) => {
      if (error) {
        console.log(error);
      } else {
        let form = document.getElementById('upload');
        let data = new FormData(form);
        data.delete(input.name); // remove binary from form to prevent 413 error
        data.append(input.name, blob.signed_id);

        fetch(form.action, {
          method: form.method,
          body: data,
        }).then(function (response) {
          let json = response.json();

          if (response.ok) {
            return json;
          }

          return json.then(Promise.reject.bind(Promise));
        }).then(function (data) {
        }).catch(function (error) {
          if (typeof error === 'object' && error.hasOwnProperty('message')) {
            setError(error.message);
          }
          setCurrentScreen(Screens.error);
        });
      }
    });
  };

  const screenCSSClasses = classnames('usd-screen', 'screen-form', {
    'usd-screen--hidden': currentScreen === Screens.upload
  });

  const dropzoneWrapperCSS = classnames('usd-upload-widget', {
    'usd-upload-widget--disabled': !agree,
  });

  const allowedStates = [Screens.form, Screens.upload];
  if(!allowedStates.includes(currentScreen)) {
    return <></>;
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3'>
          <div className={screenCSSClasses}>
            <h2 className='h--medium usd-screen__heading'>Validate Your OpenUSD Assets</h2>
            <div className='usd-screen__text'>
              <p className='mb-0'>Upload your USDZ asset package into the browser below to start the USD validation process.</p>
            </div>

            <div className={dropzoneWrapperCSS}>
              <UsdValidatorDropzone screenHandler={setCurrentScreen} errorHandler={setError} fileHandler={setFileDropped}/>
            </div>

            <div className='usd-screen__checkbox'>
              <div className='form-check'>
                <input className='form-check-input'
                       type='checkbox'
                       value=''
                       onChange={e => setAgree(e.target.checked)}
                       id='usd-validator-confirm-terms'/>
                <label className='form-check-label' htmlFor='usd-validator-confirm-terms'>
                  In addition to the <a href='https://developer.nvidia.com/legal/terms' target='_blank'>Terms of Use</a> and <a href='https://www.nvidia.com/en-us/about-nvidia/privacy-policy/' target='_blank'>Privacy Policy</a> for this website, I agree that NVIDIA may retain and analyze uploaded USD assets for the purpose of improving NVIDIA services.
                </label>
              </div>
            </div>

            <div className='usd-screen__actions'>
              <button
                className='usd-screen__next-step'
                disabled={false === readyForNextStep()}
                onClick={nextStep}>
                Upload
              </button>
            </div>

            <div className='usd-screen__footer'>
              {support()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
