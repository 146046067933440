import * as React from 'react';
import {useContext, useEffect} from 'react';
import {FieldsMappingContext} from '../../Context';
import {CardDataFields} from '../../../data-mapping';

export const CardMapper = () => {
  const { card } = useContext(FieldsMappingContext);
  const [data, setData] = card;

  useEffect(() => {
    if (data === undefined) {
      setData(CardDataFields)
    }
  }, [data]);

  if (!data) {
    return <></>;
  }

  return <>
    <div className="label">Badges</div>
    <div className="form-element">
      <input type="text" value={data.badges} onChange={e => setData({...data, badges: e.target.value})}/>
      <p className="inline-hints">Comma separated list of fields</p>
    </div>

    <div className="label">Image URL</div>
    <div className="form-element">
      <input type="text" value={data.imageUrl} onChange={e => setData({...data, imageUrl: e.target.value})}/>
    </div>

    <div className="label">Pretitle</div>
    <div className="form-element">
      <input type="text" value={data.pretitle} onChange={e => setData({...data, pretitle: e.target.value})}/>
    </div>

    <div className="label">Title</div>
    <div className="form-element">
      <input type="text" value={data.title} onChange={e => setData({...data, title: e.target.value})}/>
    </div>

    <div className="label">Subtitle</div>
    <div className="form-element">
      <input type="text" value={data.subtitle} onChange={e => setData({...data, subtitle: e.target.value})}/>
    </div>

    <div className="label">Body</div>
    <div className="form-element">
      <input type="text" value={data.body} onChange={e => setData({...data, body: e.target.value})}/>
    </div>

    <div className="label">Links</div>
    <div className="form-element">
      <input type="text" value={data.links} onChange={e => setData({...data, links: e.target.value})}/>
    </div>
  </>;
};
