import {createSlice} from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    readyToUse: false,
    showTimer: false,
  },
  reducers: {
    setReadyToUse: (state, action) => {
      state.readyToUse = action.payload;
      state.showTimer = action.payload;
    },
    setTimerVisibility: (state, action) => {
      state.showTimer = action.payload;
    },
  }
});

export const {setReadyToUse, setTimerVisibility} = appSlice.actions;
