import * as React from 'react';
import {useContext, useEffect, useRef} from 'react';
import {mappingTypes} from '../../data-mapping';
import {FieldsMappingContext} from '../Context';
import {CollectionInfo} from './field-mappings/CollectionInfo';
import {CardMapper} from './field-mappings/CardMapper';
import {AccordionMapper} from './field-mappings/AccordionMapper';

/**
 * Fields mapping form.
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.type
 * @returns {JSX.Element}
 * @constructor
 */
const FieldsMapper = ({label, type}) => {
  let mapperForm;
  switch (type) {
    case mappingTypes.card:
      mapperForm = <CardMapper />
      break;
    case mappingTypes.accordion:
      mapperForm = <AccordionMapper />
      break
    default:
      mapperForm = <p>This feature is currently unavailable.</p>
  }

  return (
    <div className="fs-fields-mapper">
      <div className="fs-fields-mapper__label">{label}</div>
      <div className="fs-fields-mapper__form">{mapperForm}</div>
    </div>
  );
};

export const FacetedSearchFieldMappings = ({info}) => {
  const mappingDataStorage = useRef(document.getElementById('typesense_collection_field_mappings'));
  const {card, accordion} = useContext(FieldsMappingContext);
  const [cardData, setCardData] = card;
  const [accordionData, setAccordionData] = accordion;

  useEffect(() => {
    if (!mappingDataStorage.current.innerHTML) {
      return;
    }
    try {
      const defaultValue = JSON.parse(mappingDataStorage.current.innerHTML);
      setCardData(defaultValue.card);
      setAccordionData(defaultValue.accordion);
    } catch (e) {
      setCardData({});
      setAccordionData({});
    }
  }, []);

  // Update field storage.
  useEffect(() => {
    mappingDataStorage.current.innerHTML = JSON.stringify({
      card: cardData,
      accordion: accordionData,
    })
  }, [cardData, accordionData]);

  return <div className="fs-admin-widget fs-admin-fields-mapping">
    <div className="columns">
      <div className="column">
        <h3 className="fs-admin-panel-title">Field mappings</h3>
        <CollectionInfo collection={info}/>
      </div>
      <div className="column scroll-x">
        <div className="fs-fields-mappers">
          <FieldsMapper label="Card" type={mappingTypes.card} />
          <FieldsMapper label="Accordion" type={mappingTypes.accordion} />
        </div>
      </div>
    </div>
  </div>
}
