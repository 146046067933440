import * as React from 'react';
import { useContext } from 'react';
import {ScreenContext, ErrorContext, PingContext, StartContext} from './context';
import { Screens } from './enum';
import {support} from './utils';

export default function Validate() {
  const [currentScreen, setCurrentScreen] = useContext(ScreenContext);
  const [pingContext, setPingContext] = useContext(PingContext);
  const [startContext, setStartContext] = useContext(StartContext);
  const [error, setError] = useContext(ErrorContext);

  if (currentScreen !== Screens.validation) {
    return <></>;
  }

  function back() {
    setPingContext(false);

    try {
      GFN.streamer.stop();
    } catch (e) {
      console.log(`Failed to stop streamer. Reason ${e.message}`);
    }
    setError(null);
    setCurrentScreen(Screens.form);
    setStartContext(startContext + 1);
  }

  return (
    <div className='usd-screen screen-upload'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 offset-lg-3'>
            <h2 className='h--medium usd-screen__heading'>Validation in Progress</h2>
            <div className='usd-screen__text'>
              <p>Your file is being validated, we will have results shortly.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid p-0'>
        <div className='row p-0 m-0'>
          <div className='col p-0 m-0'>
            <div className='image-wall'>
              <div className='img-crawl'>
                <div className='img-crawl-rows'>
                  <div className='img-crawl-row img-crawl-img-1 img-crawl-row-1'></div>
                  <div className='img-crawl-row img-crawl-img-2 img-crawl-row-2'></div>
                  <div className='img-crawl-row img-crawl-img-3 img-crawl-row-3'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 offset-lg-3'>
            <div className='usd-screen__footer'>
              <p>Having issues? <a onClick={back}>Try restarting the process</a>.</p>
              {support()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
