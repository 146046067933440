import * as React from 'react';

export const CollectionInfo = ({collection}) => {
  if (!collection || !collection.name) {
    return <>Unable to fetch collection details.</>
  }

  const {name, num_documents, fields} = collection;

  return <div className="collection-fields-info">
    {fields && fields.map((field, key) => {
      return <div key={key} className="collection-fields-info-field">
        <div className="collection-fields-info-field__name">{field.name}</div>
        <div className="collection-info-field__type">{field.type}</div>
        <div>{field.facet}</div>
      </div>
    })}
  </div>;
};
