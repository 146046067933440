import {createSlice} from '@reduxjs/toolkit';
import {AppErrorCode} from "../enum";

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    code: AppErrorCode.ok,
  },
  reducers: {
    setErrorCode: (state, action) => {
      state.code = action.payload;
    },
  },
});

export const {setErrorCode} = errorSlice.actions;
