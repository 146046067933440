import * as React from 'react';
import {useEffect, useState} from 'react';

const buildWidgetOptions = (fieldType) => {
  if (fieldType === 'string') {
    return <>
      <option value="">None</option>
      <option value="menu_select">MenuSelect</option>
      <option value="checkboxes">Checkboxes</option>
    </>;
  } else if (fieldType === 'string[]') {
    return <>
      <option value="">None</option>
      <option value="checkboxes">Checkboxes</option>
    </>
  }
  return <></>;
}

export const FacetedSearchFilterOptions = ({field, selectedFields, onChangeCallback}) => {
  const [formData, setFormData] = useState({
    enabled: field.enabled,
    fieldName: field.fieldName,
    label: field.label,
    weight: field.weight,
    widget: field.widget,
    widgetSettings: field.widgetSettings,
    compactMode: field.compactMode,
  });

  useEffect(() => {
    selectedFields[field.fieldName] = formData;
    onChangeCallback(selectedFields);
  }, [formData]);

  let selectOptions = buildWidgetOptions(field.type);

  return <tr className={'filter-options'}>
    <td>
      <input type="checkbox" checked={formData.enabled} onChange={e => setFormData({...formData, enabled: e.target.checked})}/>
    </td>
    <td>
      {field.fieldName}
    </td>
    <td>
      {field.type}
    </td>
    <td>
      <input type="text" value={formData.label} onChange={e => setFormData({...formData, label: e.target.value})}/>
    </td>
    <td>
      <select className={`filter-options__${formData.widget}`} value={formData.widget} onChange={e => setFormData(({...formData, widget: e.target.value}))}>
        {selectOptions}
      </select>
    </td>
    <td>
      <input type="number" min="0" max="100" value={formData.weight}
             onChange={e => {
               try {
                 setFormData({...formData, weight: parseInt(e.target.value)});
               } catch (e) {
                 setFormData({...formData, weight: 0});
               }
             }}/>
    </td>
    <td>
      <input type="checkbox" value={formData.compactMode} checked={formData.compactMode}
             onChange={e => {
               try {
                 setFormData({...formData, compactMode: e.target.checked});
               } catch (e) {
                 setFormData({...formData, compactMode: false});
               }
             }}/>
    </td>
  </tr>
};
