import * as React from 'react';
import {useEffect, useRef, useState} from 'react';

const sortingDefaults = {
  enabled: false,
  fieldName: '',
  label: '',
  weight: 0,
  type: '',
  sortingDesc: false,
  labelSortingDesc: ''
};

const updateAvailableFields = (fields, setter) => {
  const sortableFields = fields.filter(item => item.hasOwnProperty('sort') && item.sort === true);
  setter(sortableFields);
};

const fieldToConfig = (field, settings) => {
  const fieldName = field.name;
  const config = Object.assign({}, sortingDefaults)
  config.fieldName = fieldName;
  config.type = field.type;
  if (settings && settings[fieldName]) {
    for (const property of ['enabled', 'label', 'weight', 'sortingDesc', 'labelSortingDesc']) {
      if (settings[fieldName][property]) {
        config[property] = settings[fieldName][property];
      }
    }
  }
  return config;
};

const SortingWidgetRow = (props) => {
  const {field, selectedFields, onChangeCallback} = props;
  const [formData, setFormData] = useState({
    enabled: field.enabled,
    fieldName: field.fieldName,
    label: field.label,
    weight: field.weight,
    sortingDesc: field.sortingDesc,
    labelSortingDesc: field.labelSortingDesc,
  });

  useEffect(() => {
    selectedFields[field.fieldName] = formData;
    onChangeCallback(selectedFields);
  }, [formData]);

  return (<tr className="block">
    <td>
      <input type="checkbox" checked={formData.enabled} onChange={e => setFormData({...formData, enabled: e.target.checked})}/>
    </td>
    <td>
      {field.fieldName}
    </td>
    <td>
      {field.type}
    </td>
    <td>
      <input type="text" value={formData.label} onChange={e => setFormData({...formData, label: e.target.value})}/>
    </td>
    <td>
      <input type="checkbox" checked={formData.sortingDesc} onChange={e => setFormData({...formData, sortingDesc: e.target.checked})}/>
    </td>
    <td>
      <input type="text" value={formData.labelSortingDesc} onChange={e => setFormData({...formData, labelSortingDesc: e.target.value})}/>
    </td>
    <td>
      <input type="number" min="0" max="100" value={formData.weight}
             onChange={e => {
               try {
                 setFormData({...formData, weight: parseInt(e.target.value)});
               } catch (e) {
                 setFormData({...formData, weight: 0});
               }
             }}/>
    </td>
  </tr>);
};

/**
 * "Sort by" widget.
 *
 * @param {object} props
 * @param {object} props.info
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchSortBy = ({info}) => {
  const dataStorageElement = useRef(document.getElementById('typesense_collection_sort_by'));
  const [fieldValue, setFieldValue] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [availableFields, setAvailableFields] = useState([]);

  // Init filters.
  useEffect(() => {
    if (!dataStorageElement.current.innerHTML) {
      return;
    }
    const html = dataStorageElement.current.innerHTML;
    setFieldValue(JSON.parse(html));
  }, []);

  useEffect(() => updateAvailableFields(info.fields, setAvailableFields), [info]);

  const widgetHandler = (val) => {
    setSelectedFields(val);
    dataStorageElement.current.innerHTML = JSON.stringify(val);
  }

  return <div className="fs-admin-widget fs-admin-sorting-mapping">
    <div className="columns">
      <div className="column">
        <h3 className="fs-admin-panel-title">Sort by</h3>
      </div>
      <div className="column">
        <table className="fs-admin-table">
          <thead>
          <tr>
            <th className="col-enabled">Enabled</th>
            <th>Name</th>
            <th>Type</th>
            <th>Label (ASC)</th>
            <th className="col-enabled-alt">"Sort in descending order" enabled</th>
            <th>Label (DESC)</th>
            <th className="col-weight">Weight</th>
          </tr>
          </thead>
          <tbody>
          {availableFields
            .map(f => fieldToConfig(f, fieldValue))
            .map(f => <SortingWidgetRow key={f.fieldName} field={f} onChangeCallback={widgetHandler} selectedFields={selectedFields} />)}
          </tbody>
        </table>
        <p>Items are ordered by increasing weight.</p>
        <hr />
      </div>
    </div>
  </div>;
};
