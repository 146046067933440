import * as React from 'react';

export default function Breadcrumbs() {
  return (
    <div className='breadcrumb-container'>
      <nav aria-label='breadcrumbs'>
        <ol className='breadcrumb breadcrumb--usd'>
          <li className='breadcrumb-item'><a href='/'>Home</a></li>
          <li className='breadcrumb-item'><a href='/omniverse'>Omniverse</a></li>
          <li className='breadcrumb-item'>RunUSD</li>
          <li className='breadcrumb-item active' aria-current='page'>Validator</li>
        </ol>
      </nav>
    </div>
  );
}
