import * as React from 'react';
import {useContext, useEffect} from 'react';
import {ScreenContext, ErrorContext, PingContext, StartContext} from './context';
import {AppErrorCode, Screens} from './enum';
import {setErrorCode} from "./store/error";
import {useDispatch, useSelector} from "react-redux";
import {support} from './utils';
import {setStarted as setTimerStarted} from './store/timer';

/**
 * Default error component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const DefaultErrorMessage = () => {
  return (
    <>
      <p className='mb-0'>Sorry, there was a glitch.</p>
    </>
  );
};

/**
 * Error message component.
 *
 * @param error
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorMessage = ({error}) => {
  return typeof error === 'string' ? <p className='mb-0'>{error}</p> : <DefaultErrorMessage/>;
}

/**
 * "Browser not supported" component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const BrowserNotSupported = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3'>
          <div className='usd-screen screen-error'>
            <h2 className='h--medium usd-screen__heading'>Check Back Soon</h2>
            <div className='usd-screen__text'>
              <p className='p--medium'>The USD asset validator is not yet available in your location or on your device.</p>
              <p className='p--medium'>You can experience it on a Windows, macOS or Chrome OS device with the latest Google Chrome or Microsoft Edge browsers. You can also experience it on iOS or iPad on Safari, and Android on Google Chrome.</p>
            </div>
            <div className='usd-screen__actions'>
              <a className='usd-screen__next-step' href='/'>Back Home</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Error screen component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Error() {
  const [currentScreen, setCurrentScreen] = useContext(ScreenContext);
  const [pingContext, setPingContext] = useContext(PingContext);
  const [startContext, setStartContext] = useContext(StartContext);
  const [error, setError] = useContext(ErrorContext);
  const dispatch = useDispatch();

  const errorCode = useSelector(state => state.errorHandler.code);

  if (currentScreen !== Screens.error) {
    return <></>;
  }

  useEffect(() => {
    dispatch(setTimerStarted(false));
    setPingContext(false);
    try {
      GFN.streamer.stop();
    } catch (error) {
    }
  }, []);

  function back() {
    setError(null);
    // Reset error code
    dispatch(setErrorCode(AppErrorCode.ok));

    setCurrentScreen(Screens.form);
    setStartContext(startContext + 1);
  }

  if (errorCode === AppErrorCode.browserNotSupported) {
    return <BrowserNotSupported back={back}/>
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col'>
          <div className='usd-screen screen-error'>
            <h2 className='h--medium usd-screen__heading'>Error</h2>
            <div className='usd-screen__text'>
              <ErrorMessage error={error}/>
            </div>
            <div className='usd-screen__icon'></div>
            <div className='usd-screen__actions'>
              <button className='usd-screen__next-step' onClick={back}>
                Back Home
              </button>
            </div>
            <div className='usd-screen__footer'>
              {support()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
