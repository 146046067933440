import * as React from "react";

import { Screens } from './enum';
import {useSelector} from "react-redux";

export const ProgressBar = (props) => {
  const {screen} = props;
  const uploadProgress = useSelector(state => state.upload.progress);
  let progress = 0;
  let cssClasses = [[],[],[]];
  switch (screen) {
    case Screens.form:
      cssClasses = [
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step', 'usd-progress-bar__item--active'],
        ['usd-progress-bar__item'],
        ['usd-progress-bar__item'],
      ];
      break;
    case Screens.upload:
      cssClasses = [
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step', 'usd-progress-bar__item--active'],
        ['usd-progress-bar__item'],
        ['usd-progress-bar__item'],
      ];
      progress = uploadProgress / 2;
      break;
    case Screens.validation:
      cssClasses = [
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step'],
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step', 'usd-progress-bar__item--active'],
        ['usd-progress-bar__item'],
      ];
      progress = 50;
      break;
    case Screens.report:
      cssClasses = [
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step'],
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step',],
        ['usd-progress-bar__item', 'usd-progress-bar__item--active-step', 'usd-progress-bar__item--active'],
      ];
      progress = 100;
      break;
  }

  if (screen === Screens.error) {
    return (
      <div className='usd-progress-bar usd-progress-bar--hidden'></div>
    );
  }

  return (
    <div className='container'>
    <div className='row'>
      <div className='col-lg-6 offset-lg-3'>
        <div className='usd-progress-bar'>
          <ul className='usd-progress-bar__steps'>
            <li className={cssClasses[0].join(' ')}>Upload file</li>
            <li className={cssClasses[1].join(' ')}>Validate</li>
            <li className={cssClasses[2].join(' ')}>Report</li>
          </ul>
          <div className='usd-progress-bar__line'>
            <div className="usd-progress-bar__progress" style={{width: `${progress}%`}}></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};
