import * as React from 'react';
import Countdown, {zeroPad} from 'react-countdown';
import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {cleanupError, setCompleted, setHasError, setStarted as setTimerStarted} from './store/timer';

/**
 * Renders timer content.
 *
 * @param {Number} minutes
 * @param {Number} seconds
 * @returns {JSX.Element}
 */
function timerRenderer({minutes, seconds}) {
  return (
    <span className='usd-timer__time'>
      {zeroPad(minutes)}:{zeroPad(seconds)}
    </span>
  );
}

/**
 * Timer component.
 *
 * https://github.com/ndresx/react-countdown
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Timer = () => {
  const dispatch = useDispatch();
  const expirationTime = Date.now() + useSelector(state => state.timer.delay);
  const isTimerStarted = useSelector(state => state.timer.isStarted);
  const isTimerCompleted = useSelector(state => state.timer.isCompleted);
  const isVisible = useSelector(state => state.app.showTimer);
  const clockRef = useRef();

  useEffect(() => {
    if (clockRef.current && isTimerStarted) {
      clockRef.current.start();
      dispatch(cleanupError());
      dispatch(setCompleted(false));
    }
  }, [clockRef.current, isTimerStarted]);

  const onComplete = () => {
    dispatch(setTimerStarted(false));
    dispatch(setCompleted(true));

    try {
      GFN.streamer.stop();
    } catch (e) {
      dispatch(setHasError({status: true, message: 'Failed to stop streamer'}));
    }
  };

  if (!isVisible) {
    return null;
  }

  function countdown() {
    if (!isTimerStarted) {
      return (
        <span>00:00</span>
      );
    }

    return (
      <Countdown
        daysInHours={true}
        autoStart={false}
        onComplete={onComplete}
        ref={clockRef}
        renderer={timerRenderer}
        date={expirationTime}/>
    );
  }

  return (
    <div className='usd-timer'>
      <span className="usd-timer__label">Time remaining in session:</span> {countdown()}
    </div>
  );
}

export default Timer;
