import {createSlice} from '@reduxjs/toolkit';

export const uploadProgressSlice = createSlice({
  name: 'upload',
  initialState: {
    progress: 0,
  },
  reducers: {
    updateProgress: (state, action) => {
      state.progress = action.payload;
    },
  },
});

export const {updateProgress} = uploadProgressSlice.actions;
