import React, { useEffect, useRef } from 'react';
import {Routes} from './enum';

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export const downloadSummaryReport = () => {
  window.downloadSummaryClicked = true;
  window.location = Routes.report;
}

export const support = () => {
  return (
    <p>For questions about RunUSD, check out our <a href='https://forums.developer.nvidia.com/t/runusd-validation-service/262049' target='_blank'>developer forum</a>.</p>
  );
}
