import * as React from 'react';
import {useContext, useEffect, useState} from 'react';

import ReactJson from 'react-json-view'
import {downloadSummaryReport, support} from './utils';
import { ScreenContext, StateContext, StartContext, PingContext } from './context';
import { Screens } from './enum';
import {useSelector} from 'react-redux';

function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

/**
 * Report header component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ReportHeader = ({ status }) => {
  const isTimerCompleted = useSelector(state => state.timer.isCompleted);

  function message() {
    let msg = '';

    switch(status) {
      case 'pass':
        msg = 'Congratulations! Your assets are validated as USD compatible.'
        break;
      case 'fail':
        msg = 'Validation discovered issues with your file.'
        break;
      default:
    }

    if (!isTimerCompleted) {
      msg += ' Below is an RTX-render of your USD asset.';
    }

    return msg;
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3'>
          <h2 className='h--medium usd-screen__heading'>Validation Complete</h2>
          <div className='usd-screen__text'>
            <p className='mb-0'>{message()}</p>
          </div>
          <div className='usd-screen__icon'></div>
        </div>
      </div>
    </div>
  );
};

/**
 * Report footer component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ReportFooter = () => {
  const [currentScreen, setCurrentScreen] = useContext(ScreenContext);
  const [startContext, setStartContext] = useContext(StartContext);
  const [pingContext, setPingContext] = useContext(PingContext);

  function back() {
    setPingContext(false);
    GFN.streamer.stop();
    setCurrentScreen(Screens.form);
    setStartContext(startContext + 1);
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3'>
          <div className='usd-screen__actions'>
            <button className='usd-screen__next-step' onClick={back}>
              Back Home
            </button>
            <button
              className='usd-screen__next-step usd-screen__next-step-alt'
              onClick={() => {
                downloadSummaryReport();
              }}
            >
              Download Summary Report
            </button>
          </div>
          <div className='usd-screen__footer'>
            {support()}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * JSON output component.
 *
 * @param {Object} json
 * @returns {JSX.Element}
 * @constructor
 */
const PrettyJson = ({ json }) => {
  if (isEmpty(json)) {
    return <></>;
  }

  return (
    <div className='usd-screen__result-text'>
      <ReactJson src={json} theme='monokai' />
    </div>
  );
};

/**
 * Report component.
 *
 * @param {Object} props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Report(props) {
  const {children} = props;

  const [currentScreen, setCurrentScreen] = useContext(ScreenContext);
  const [stateContext, ] = useContext(StateContext);
  const [json, setJson] = useState({});
  const isTimerCompleted = useSelector(state => state.timer.isCompleted);

  useEffect(() => {
    setJson(stateContext.output);
  }, [stateContext.output]);

  function cssClasses() {
    let classes = ['usd-screen', 'screen-report'];

    if (currentScreen !== Screens.report) {
      classes.push('screen-report--hidden');
    }

    if (stateContext.status === 'fail') {
      classes.push('screen-report--error');
    }

    if (isTimerCompleted) {
      classes.push('screen-report--end');
    }

    return classes.join(' ');
  }

  return (
    <div className={cssClasses()}>
      {currentScreen === Screens.report && <ReportHeader status={stateContext.status} />}

      <div className='container'>
        <div className='row' >
          <div className='col-lg-10 offset-lg-1'>
            <div id='gfn-streamer-container' className='usd-screen__result-img'>
              {children}
            </div>
            {currentScreen === Screens.report && <PrettyJson json={json} />}
          </div>
        </div>
      </div>

      {currentScreen === Screens.report && <ReportFooter/>}
    </div>
  );
}
