import * as React from "react";
import { useContext } from "react";
import { ScreenContext } from "./context";
import { Screens } from "./enum";
import Form from "./form";
import Upload from "./upload";
import Validate from "./validate";
import Error from "./error";
import Report from "./report";
import { ProgressBar } from "./progress-bar";

export default function Screen(props) {
  const [currentScreen] = useContext(ScreenContext);

  return (
    <>
      <ProgressBar screen={currentScreen} />

      <Form />
      {currentScreen === Screens.upload && <Upload />}
      {currentScreen === Screens.validation && <Validate />}
      {currentScreen === Screens.error && <Error />}
      <Report>{props.children}</Report>
    </>
  );
}
