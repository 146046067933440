import {createSlice} from '@reduxjs/toolkit';

/**
 * Timer reducer.
 */
export const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    isStarted: false,
    isCompleted: false,
    hasError: false,
    errorMessage: '',
    delay: 15 * 60 * 1000,
  },
  reducers: {
    setStarted: (state, action) => {
      state.isStarted = action.payload;
    },
    setCompleted: (state, action) => {
      state.isCompleted = action.payload;
    },
    setHasError: (state, action) => {
      const {status, message} = action.payload;
      state.hasError = status;
    },
    cleanupError:(state, action) => {
      state.hasError = false;
      state.errorMessage = '';
    }
  },
});

export const {setStarted, setCompleted, setHasError, cleanupError} = timerSlice.actions;
