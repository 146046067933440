import * as React from 'react';
import {useEffect, useState, useMemo} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import store from './usd-validator/store'
import {useInterval} from './usd-validator/utils';
import Breadcrumbs from './usd-validator/breadcrumbs';
import Timer from './usd-validator/timer';
import Screen from "./usd-validator/screen";
import {AppErrorCode, AppState, Routes, Screens, StreamingResolution} from './usd-validator/enum';
import {
  ErrorContext,
  StateContext,
  ScreenContext,
  StartContext,
  PingContext,
} from './usd-validator/context';
import {setStarted as setTimerStarted} from './usd-validator/store/timer';
import {setErrorCode} from './usd-validator/store/error';
import classnames from "classnames";
import {setReadyToUse, setTimerVisibility} from "./usd-validator/store/app";

/**
 * Returns error message by error code.
 *
 * @param {Number} code
 * @returns {string}
 */
function errorByCode(code) {
  let error = `An unexpected error was encountered. Code: ${code}`;
  switch (code) {
    case 1:
      error = 'The session was cancelled. Please try again.';
      break;
    case 2:
      error = 'An error was encountered while setting up the session. Please try again.';
      break;
    case 12:
      error = 'The validaton service is currently under maintenance. Please try again in a few minutes.';
      break;
    case 7:
    case 10:
    case 46:
      error = 'A network error was was encountered. Please try again in a few minutes.';
      break;
    case 19:
      error = 'Session limit reached. Please try again in a few minutes.';
      break;
  }

  return error;
}

function end(setScreenContext, setErrorContext) {
  fetch(Routes.end)
    .then((response) => {
      return response.ok ? response.json() : Promise.reject(response);
    })
    .then(() => {
    })
    .catch((error) => {
      console.log(error);
      setErrorContext(error.message);
      setScreenContext(Screens.error);
    });
}

function pong(setStateContext) {
  fetch(Routes.ping)
    .then((response) => {
      return response.ok ? response.json() : Promise.reject(response);
    })
    .then((data) => {
      setStateContext(data);
    })
    .catch((error) => {
      console.warn(error);
    });
}

function setupStreamerEvents(setScreenContext, setErrorContext) {
  GFN.streamer.on("terminated", (e) => {
    console.log(`The streamer has terminated. reason=${GFN.StreamerTerminationReason[e.reason]} (${GFN.utils.string.hex(e.reason, 8)}), code=${GFN.utils.string.hex(e.code ? e.code : 0, 8)}`);
    let code = e.reason;

    // 0 is sent when GFN.streamer.stop() is called, direct users back to the form screen
    if (code === 0) {
      return;
    }

    setErrorContext(errorByCode(e.reason));
    setScreenContext(Screens.error);
  });

  GFN.streamer.on("sessionSetupProgress", (e) => {
    if (e.progress === GFN.SessionSetupProgress.InQueue) {
      console.log(`Waiting in queue (eta=${e.estimatedWaitTimeInSecs} seconds), there are ${e.queuePosition} gamers ahead of you...`);
    } else {
      switch (e.progress) {
        case GFN.SessionSetupProgress.Configuring:
          console.log(`The game seat is being configured (eta=${e.estimatedWaitTimeInSecs} seconds)...`);
          break;
        case GFN.SessionSetupProgress.SessionCleanup:
          console.log(`Cleaning up previous sessions (eta=${e.estimatedWaitTimeInSecs} seconds)...`);
          break;
        case GFN.SessionSetupProgress.Starting:
          console.log(`The game is about to start (eta=${e.estimatedWaitTimeInSecs} seconds)...`);
          break;
        case GFN.SessionSetupProgress.Connected:
          console.log("The streamer is connected to the server...");
          break;
        case GFN.SessionSetupProgress.Error:
          console.log("The streamer session setup failed.");
          setErrorContext(errorByCode(e.errorDetails.reason));
          setScreenContext(Screens.error);
          break;
      }
    }
  });

  GFN.streamer.on("diagnostic", (e) => {
    if (e.message === 'The session limit has been reached.') {
      setErrorContext(errorByCode(19));
      setScreenContext(Screens.error);
    }
  });

  GFN.streamer.on("stateChanged", (e) => {
    console.log(`The streamer is now '${GFN.StreamerState[e.state]}'...`);
  });
}

/**
 * Platform cheker component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const UsdValidatorChecker = () => {
  return <div className='usd-validator-checker'>
    <div className='usd-screen screen-checker'>
      <h2 className="h--medium usd-screen__heading mb-5">Checking your browser</h2>
      <div className="usd-screen__loader mb-5"></div>
    </div>
  </div>
}

const UsdValidatorComponent = (props) => {
  const dispatch = useDispatch();

  const [screenContext, setScreenContext] = useState(Screens.form);
  const [stateContext, setStateContext] = useState({});
  const [errorContext, setErrorContext] = useState(null);
  const [startContext, setStartContext] = useState(null);
  const [pingContext, setPingContext] = useState(false);

  const readyToUse = useSelector(state => state.app.readyToUse);

  const isTimerCompleted = useSelector(state => state.timer.isCompleted);
  const timerHasError = useSelector(state => state.timer.hasError);

  const handleError = (error) => {
    setErrorContext(error.message);
    setScreenContext(Screens.error);
  };

  let currentServerInfo = null;

  useEffect(() => {
    setStartContext(1);
  }, []);

  useEffect(() => {
    if (isTimerCompleted && [Screens.form, Screens.upload, Screens.validation].includes(screenContext)) {
      setErrorContext('The session has expired.');
      setScreenContext(Screens.error);
      setPingContext(false);
    }
  }, [isTimerCompleted]);

  useEffect(() => {
    //if (timerHasError) {}
  }, [timerHasError]);

  async function startStreaming() {
    const startResponse = await fetch(Routes.start);
    if (!startResponse.ok) {
      throw new Error('Failed to start streaming.');
    }
    const startResponseJson = await startResponse.json();

    await initializeGFN(startResponseJson.partnerId);
    dispatch(setReadyToUse(true));
    if (!GFN.streamer.isSupported) {
      dispatch(setTimerVisibility(false));
      dispatch(setErrorCode(AppErrorCode.browserNotSupported));
      throw new Error('Streaming is not supported on this platform.');
    }
    setupStreamerEvents(setScreenContext, setErrorContext);
    await GFN.streamer.stop();
    await doLoginWithNonce(startResponseJson.nonce, startResponseJson.cmsId);
    await doStartStream(startResponseJson.cmsId);
  }

  useEffect(() => {
    dispatch(setTimerStarted(false));

    if (typeof startContext !== 'number') {
      return;
    }

    startStreaming().then(() => {
      setPingContext(true);
      dispatch(setTimerStarted(true));
    }).catch(error => {
      handleError(error)
    });
  }, [startContext]);

  useEffect(() => {
    if (stateContext.state === AppState.uploadComplete) {
      setScreenContext(Screens.validation);
    } else if (stateContext.state === AppState.error) {
      setScreenContext(Screens.error);
    }
  }, [stateContext.state]);

  useEffect(() => {
    if (stateContext.ready && stateContext.status !== null) {
      end(setScreenContext, setErrorContext);
      setScreenContext(Screens.report);
    }
  }, [stateContext.ready, stateContext.status]);

  useInterval(() => {
    if (!pingContext) {
      return;
    }
    pong(setStateContext);
  }, 1000);

  async function initializeGFN(partnerId) {
    const catalogClientId = 'f18dab56-2dee-4195-ae3c-3147e7f90360';

    const settings = new GFN.Settings({
      catalogClientId: catalogClientId,
      partnerId: partnerId,
      configOverrides: {
        streamer: {
          web: {
            enableMicrophone: false
          }
        }
      }
    });

    await GFN.initialize(settings);
  }

  async function doLoginWithNonce(nonce, cmsId) {
    if (GFN.isLoggedIn) {
      return;
    }

    await GFN.auth.loginWithNonce(nonce, cmsId);
  }

  async function configureServerInfoSettings() {
    if (currentServerInfo) {
      return;
    }

    const serverInfo = await GFN.server.getServerInfo();

    currentServerInfo = serverInfo;

    if (!GFN.settings.vpcId) {
      GFN.settings.vpcId = serverInfo.vpcId;
    }
  }

  async function getStreamingServer(server) {
    if (server === "auto") {
      try {
        await configureServerInfoSettings();
        server = currentServerInfo.defaultZone;
      } catch (e) {
        console.error("ERROR: ", e);
      }

      if (server === "auto") {
        throw new Error("Unable to determine the streaming service URL.");
      }
    }

    return Promise.resolve(server);
  }

  async function doStartStream(cmsId) {
    let gameValue = parseInt(cmsId);

    const serverValue = 'auto';
    const fpsValue = '60';
    const fps = parseInt(fpsValue);
    const profilePreset = 'custom';
    const windowed = true;
    const partnerData = '';
    const adjustForPoorNetwork = 'Default';

    if (!GFN.isLoggedIn) {
      throw new Error("No user has been authorized.");
    }

    const server = await getStreamingServer(serverValue);
    const startParams = {
      server: server,
      appId: parseInt(gameValue),
      ...(profilePreset !== "custom") && {profilePreset:profilePreset},
      ...(profilePreset === "custom") && {
        streamParams: {
          width: StreamingResolution.width,
          height: StreamingResolution.height,
          fps: fps
        }
      },
      authTokenCallback: async () => {
        return GFN.currentUser.authInfo.idToken.value;
      },
      ...windowed && {windowElementId: "gfn-streamer-window"},
      ...partnerData && {partnerData: partnerData},
      ...adjustForPoorNetwork !== "Default" && {enableDRC: adjustForPoorNetwork==="YES"}
    };

    await GFN.streamer.start(startParams)
  }

  // TODO: Replace contexts by redux reducers.
  //
  // https://github.com/JedWatson/classnames
  return (
    <>
    <div className={classnames('usd-validator', 'text-center', {'usd-validator--hidden': !readyToUse})}>
      <PingContext.Provider value={[pingContext, setPingContext]}>
        <StartContext.Provider value={[startContext, setStartContext]}>
          <ErrorContext.Provider value={[errorContext, setErrorContext]}>
            <StateContext.Provider value={[stateContext, setStateContext]}>
              <ScreenContext.Provider value={[screenContext, setScreenContext]}>
                <Screen>
                  {props.children}
                </Screen>
              </ScreenContext.Provider>
            </StateContext.Provider>
          </ErrorContext.Provider>
        </StartContext.Provider>
      </PingContext.Provider>
    </div>
    <UsdValidatorChecker />
    </>
  )
};

const UsdValidator = () => {
  const [streamerInitialized, ] = useState(true);

  const StreamerWindow = () =>
  useMemo(() => {
    return <div id='gfn-streamer-window'></div>;
  }, [streamerInitialized]);

  return (
    <>
      <Provider store={store}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6 col-lg-7'>
              <Breadcrumbs />
            </div>
            <div className='col-md-6 col-lg-5'>
              <Timer />
            </div>
          </div>
        </div>
        <UsdValidatorComponent>
          <StreamerWindow />
        </UsdValidatorComponent>
      </Provider>
    </>
  );
}

export default UsdValidator;
