import * as React from 'react';
import { useContext } from 'react';
import {ScreenContext} from './context';
import { Screens } from './enum';
import {useSelector} from 'react-redux';
import {support} from './utils';

const formatProgress = (progress) => {
  return `${Math.round(progress)}%`;
};

/**
 * Upload screen component.
 *
 * @returns {JSX.Element}
 */
export default function Upload() {
  const [currentScreen] = useContext(ScreenContext);
  const uploadProgress = useSelector(state => state.upload.progress);

  if (currentScreen !== Screens.upload) {
    return <></>;
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 offset-lg-3'>
          <div className='usd-screen screen-upload'>
            <h2 className='h--medium usd-screen__heading'>Upload in Progress</h2>
            <div className='usd-screen__text'>
              <p className='mb-0'>
                Thanks for submitting your assets for USD validation.
              </p>
            </div>
            <div className='usd-screen__loader'>
              <div className='usd-screen__upload-progress'>{formatProgress(uploadProgress)}</div>
            </div>
            <div className='usd-screen__footer'>
              {support()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
