export const Screens = {
  form: 'form',
  upload: 'upload',
  validation: 'validation',
  error: 'error',
  report: 'report',
};

export const AppState = {
  uploadComplete: 'UPLOAD_COMPLETE',
  validationComplete: 'VALIDATION_COMPLETE',
  validationStarted: 'VALIDATION_STARTED',
  error: 'ERROR'
}

export const Routes = {
  start: '/usd/validation-service/start',
  end: '/usd/validation-service/end',
  ping: '/usd/validation-service/ping',
  report: '/usd/validation-service/report',
};

export const StreamingResolution = {
  width: 1600,
  height: 1200,
}

export const AppErrorCode = {
  ok: 0,
  browserNotSupported: 1,
};
