import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {uploadProgressSlice} from "./store/upload-progress";
import {timerSlice} from "./store/timer";
import {errorSlice} from "./store/error";
import {appSlice} from "./store/app";

const reducer = combineReducers({
  upload: uploadProgressSlice.reducer,
  timer: timerSlice.reducer,
  errorHandler: errorSlice.reducer,
  app: appSlice.reducer,
});

const store = configureStore({reducer});

export default store;
