import * as React from 'react';
import {useContext, useEffect} from 'react';
import {FieldsMappingContext} from '../../Context';
import {AccordionDataFields} from '../../../data-mapping';

export const AccordionMapper = () => {
  const { accordion } = useContext(FieldsMappingContext);
  const [data, setData] = accordion;

  useEffect(() => {
    if (data === undefined) {
      setData(AccordionDataFields)
    }
  }, [data]);

  return <>
    <div className="label">Image URL</div>
    <div className="form-element">
      <input type="text" value={data.imageUrl} onChange={e => setData({...data, imageUrl: e.target.value})}/>
    </div>

    <div className="label">Title</div>
    <div className="form-element">
      <input type="text" value={data.title} onChange={e => setData({...data, title: e.target.value})}/>
    </div>

    <div className="label">Badge</div>
    <div className="form-element">
      <input type="text" value={data.badge} onChange={e => setData({...data, badge: e.target.value})}/>
    </div>

    <div className="label">Body</div>
    <div className="form-element">
      <input type="text" value={data.body} onChange={e => setData({...data, body: e.target.value})}/>
    </div>

    <div className="label">Markdown</div>
    <div className="form-element">
      <input type="text" value={data.markdown} onChange={e => setData({...data, markdown: e.target.value})}/>
    </div>

    <div className="label">Description list</div>
    <div className="form-element">
      <textarea value={data.descriptionList} onChange={e => setData({...data, descriptionList: e.target.value})}></textarea>
      <p className="inline-hints">One field per line.</p>
    </div>

    <div className="label">Description list labels</div>
    <div className="form-element">
      <textarea value={data.descriptionListLabels} onChange={e => setData({...data, descriptionListLabels: e.target.value})}></textarea>
      <p className="inline-hints">One label per line (order should match "description list" values).</p>
    </div>

    <div className="label">Links</div>
    <div className="form-element">
      <input type="text" value={data.links} onChange={e => setData({...data, links: e.target.value})}/>
    </div>
  </>;
};
