import * as React from 'react';
import {StatusIcon} from '../helper';

/**
 * @param {Object} field
 * @param {string} field.name
 * @param {string} field.type
 * @param {string} field.type
 * @param {boolean} field.facet
 * @param {boolean} field.sort
 * @returns {JSX.Element}
 * @constructor
 */
const FieldInfo = ({field}) => {
  return <tr>
    <td>{field.name}</td>
    <td>{field.type}</td>
    <td><StatusIcon status={field.facet}/></td>
    <td><StatusIcon status={field.sort}/></td>
  </tr>
}

/**
 * "Collection details" widget.
 *
 * @param {object} props
 * @param {object} props.info
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchCollectionDetails = ({info}) => {
  return <>
    <div className="fs-admin-widget fs-admin-collection-info">
      <div className="columns">
        <div className="column">
          <h3 className="fs-admin-panel-title">Collection details</h3>
          <div className="collection-info-properties">
            <div className="collection-info-property">
              <span>Name:</span> {info.name}
            </div>
            <div className="collection-info-property">
              <span>Size:</span> {info.num_documents}
            </div>
            <div className="collection-info-property">
              <span>Created at:</span> {new Date(info.created_at * 1000).toLocaleDateString()}
            </div>
            <div className="collection-info-property">
              <span>Default sorting field:</span> {info.default_sorting_field}
            </div>
          </div>
        </div>
        <div className="column">
          <table className="fs-admin-table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Facet</th>
              <th>Sort</th>
            </tr>
            </thead>
            <tbody>
              {info.fields.map(f => <FieldInfo key={f.name} field={f}/>)}
            </tbody>
          </table>
          <hr/>
        </div>
      </div>
    </div>
  </>
};
