import * as React from 'react';
import {useEffect, useState} from 'react';
import {FieldsMappingContextProvider, tsConnectionContext} from './faceted-search/react/Context';
import {FacetedSearchCollectionDetails} from './faceted-search/react/admin/FacetedSearchCollectionDetails';
import {FacetedSearchFilters} from './faceted-search/react/admin/FacetedSearchFilters';
import {FacetedSearchSortBy} from './faceted-search/react/admin/FacetedSearchSortBy';
import {FacetedSearchFieldMappings} from './faceted-search/react/admin/FacetedSearchFieldMappings';
import {AlertMessage, fetchCollectionDetails, handleRequestError} from './faceted-search/react/helper';
import {Code} from 'react-content-loader';

const disableSelect2 = () => {
  setTimeout(() => {
    document.querySelectorAll('.fsf-constructor .select2').forEach(e => e.remove());
    document.querySelectorAll('.fsf-constructor select.select2-hidden-accessible').forEach(e => e.classList.remove('select2-hidden-accessible'));
  }, 600);
}

const FacetedSearchBuilderWidgets = () => {
  const contextData = React.useContext(tsConnectionContext);
  const {collection: collectionName, key: ApiKey, protocol, host, port} = contextData;
  const [loaded, setLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [info, setInfo] = useState({fields:[]});

  // Fetch collection details.
  useEffect(() => {
    fetchCollectionDetails(protocol, host, port, collectionName, ApiKey)
      .then(data => {
        setLoaded(true)
        setInfo(data);
        setHasError(false);
      })
      .catch(e => {
        setLoaded(true);
        setHasError(true)
        handleRequestError(e)
      });
  }, []);

  let content;
  if (loaded) {
    if (hasError) {
      content = <AlertMessage title="Typesense connection failed" type="error">
        <p>For assistance, please contact <a href="mailto:DevZone-Ops@exchange.nvidia.com">DevZone-Ops</a>.</p>
      </AlertMessage>
    } else {
      content = <>
        <FieldsMappingContextProvider>
          <FacetedSearchCollectionDetails info={info}/>
          <FacetedSearchSortBy info={info}/>
          <FacetedSearchFilters info={info}/>
          <FacetedSearchFieldMappings info={info}/>
        </FieldsMappingContextProvider>
      </>
    }
  } else {
    content = <Code speed={2} width={600} height={450} viewBox="0 0 600 450"/>  }

  return <div className="fs-admin-widgets">
    {content}
  </div>
}

export default function FacetedSearchBuilder(props) {
  const {ts_host, ts_port, ts_key, ts_protocol, collection} = props;
  const [tsConnection] = useState({
    key: ts_key,
    host: ts_host,
    port: ts_port,
    protocol: ts_protocol,
    collection: collection
  });

  // TODO: Find a workaround to disable select2 for builder elements.
  // Select2 does not work correctly with React.
  useEffect(disableSelect2, []);

  return <tsConnectionContext.Provider value={tsConnection}>
    <FacetedSearchBuilderWidgets/>
  </tsConnectionContext.Provider>;
}
